export default {
	items: [
		{
			name: 'Dashboard',
			url: '/admin/dashboard',
			icon: 'icon-speedometer',
			badge: {
				variant: 'primary'
			}
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/admin/company',
					icon: 'fa fa-building-o'
				},
				{
					name: 'Storage Location',
					url: '/admin/admin-location',
					icon: 'fa fa-map-marker',
				},
				{
					name: 'User',
					url: '/admin/admin-user',
					icon: 'fa fa-users',
				},
				{
					name: 'Connection',
					url: '/admin/admin-connection',
					icon: 'fa fa-link',
				},
				{
					name: 'Transportation',
					url: '/admin/admin-transportation',
					icon: 'fa fa-truck'
				},
				{
					name: 'Asset Type',
					url: '/admin/admin-asset-type',
					icon: 'fa fa-tags',
				},
			]
		},
		{
			name: 'Asset Management',
			url: '/asset',
			icon: 'fa fa-tasks',
			children: [
				{
					name: 'Asset',
					url: '/admin/admin-asset',
					icon: 'icon-star'
				},
				{
					name: 'Asset Sticker Generator',
					url: '/admin/sticker-generator',
					icon: 'fa fa-print'
				},
				{
					name: 'Asset Issuance',
					url: '/admin/admin-asset-issuance',
					icon: 'fa fa-tag'
				},
				{
					name: 'Asset History Log',
					url: '/admin/admin-asset-history-log',
					icon: 'fa fa-history'
				}
			]
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-list',
			children: [
				{
					name: 'Inventory Session',
					url: '/admin/admin-inventory',
					icon: 'fa fa-bar-chart'
				},
				{
					name: 'Dispatch',
					url: '/admin/admin-dispatch',
					icon: 'fa fa-truck',
				},
				{
					name: 'Receipt',
					url: '/admin/admin-receipt',
					icon: 'fa fa-map-pin'
				},
			]
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Asset Accountability',
					url: '/admin/admin-asset-accountability',
					icon: 'fa fa-list-alt'
				},
				{
					name: 'Asset Pool Distribution',
					url: '/admin/admin-asset-pool-distribution',
					icon: 'fa fa-cubes'
				},
				{
					name: 'Asset Recon',
					url: '/admin/admin-asset-recon',
					icon: 'fa fa-search'
				},
				{
					name: 'Assets With Truckers',
					url: '/admin/admin-assets-with-truckers',
					icon: 'fa fa-truck'
				},
				{
					name: 'Inventory Compliance',
					url: '/admin/admin-inventory-compliance',
					icon: 'fa fa-check-circle'
				},
				{
					name: 'Inventory Scan Progress',
					url: '/admin/admin-inventory-scan-progress',
					icon: 'icon-graph'
				},
				{
					name: 'Inventory Summary',
					url: '/admin/admin-inventory-summary',
					icon: 'fa fa-table',
				},
				{
					name: 'User Inventory Scans',
					url: '/admin/admin-user-inventory-scans',
					icon: 'fa fa-barcode'
				}
			]
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Stencil Generator',
					url: '/admin/admin-stencil-generator',
					icon: 'fa fa-tags'
				},
				{
					name: 'Transfer Company',
					url: '/admin/admin-transfer-company-requests',
					icon: 'fa fa-exchange'
				},
				{
					name: 'User Session Logs',
					url: '/admin/admin-user-session-logs',
					icon: 'fa fa-key'
				},
				{
					name: 'User Tickets',
					url: '/admin/admin-user-tickets',
					icon: 'fa fa-ticket'
				}
			]
		},
		{
			name: 'Data Importer',
			url: '/data-importer',
			icon: 'fa fa-clipboard',
			children: [
				{
					name: 'Asset Importer',
					url: '/admin/asset-importer',
					icon: 'fa fa-clipboard'
				},
				{
					name: 'Company Importer',
					url: '/admin/company-importer',
					icon: 'fa fa-clipboard'
				},
				{
					name: 'Dispatch Importer',
					url: '/admin/dispatch-importer',
					icon: 'fa fa-clipboard'
				},
				{
					name: 'Location Importer',
					url: '/admin/storage-location-importer',
					icon: 'fa fa-clipboard'
				},
				{
					name: 'Transportation Importer',
					url: '/admin/transportation-importer',
					icon: 'fa fa-clipboard'
				},
				{
					name: 'User Importer',
					url: '/admin/user-importer',
					icon: 'fa fa-clipboard'
				},
			]
		},
		{
			name: 'Website Content',
			url: '/website-content',
			icon: 'icon-globe',
			children: [
				{
					name: 'Blogs',
					url: '/admin/blogs',
					icon: 'fa fa-rss-square'
				},
				{
					name: 'FAQs',
					url: '/admin/faqs',
					icon: 'fa fa-question'
				},
				{
					name: 'Inquiries',
					url: '/admin/inquiries',
					icon: 'fa fa-comment'
				},
			]
		}
	]
};
